<script>
export default {
	props: {
		value: Object,
		selected: Boolean,
		data: Object,
	},
	data() {
		return {
			imedCouponCode: this.value.imedCouponCode,
		}
	},
	watch: {
		imedCouponCode() {
			this.$emit('input', { imedCouponCode: this.imedCouponCode })
		},
	},
	computed: {
		title() {
			return this.data?.title || 'Cupones de Laboratorio'
		},
	},
}
</script>

<template>
	<div>
		<div>
			<b>{{ title }}</b>
			<div>
				<small>Ingresa los dígitos que figuran dentro del Cupón en el Código de Barras</small>
			</div>
		</div>
		<div v-if="selected" class="pt-4">
			<TextField
				v-model="imedCouponCode"
				label="Código del cupón"
				placeholder="XXXXXXXXXXXXXXXX"
				outlined
				dense
			/>
		</div>
	</div>
</template>

