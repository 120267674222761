<script>
export default {
	props: {
		value: Object,
		selected: Boolean,
	},
	data() {
		return {
			clndni: this.value.clndni,
			clncard: this.value.clncard,
		}
	},
	watch: {
		clndni() {
			this.$emit('input', { clndni: this.clndni, clncard: this.clncard })
		},
		clncard() {
			this.$emit('input', { clndni: this.clndni, clncard: this.clncard })
		},
	},
}
</script>

<template>
	<div>
		<div>
			<b>Club La Nación</b>
			<div>Ingresa los datos de tu tarjeta Club La Nación</div>
			<small>No acumulable con otras promociones</small>
		</div>
		<div v-if="selected" class="pt-4">
			<div class="pb-4">
				<TextField
					v-model="clndni"
					label="DNI"
					placeholder="Ingresa el DNI del titular de la tarjeta"
					dense
				/>
			</div>
			<div>
				<TextField
					v-model="clncard"
					label="Número de Tarjeta"
					placeholder="Ingresa el número impreso en tu tarjeta"
					dense
				/>
			</div>
		</div>
	</div>
</template>

